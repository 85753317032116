<template>
  <div class="not-found-container">
    <div>
      <div class="justify-content-center not-found-inner-container">
        <div class="not-found-text not-found-numbers align-middle">404</div>
        <h1 class="not-found-text not-found-message">
          {{ $t("notfound.header") }}
        </h1>
      </div>
      <div class="justify-content-center not-found-inner-container">
        <router-link :to="{ name: 'home' }">
          <button class="not-found-button">
            <span>{{ $t("notfound.button") }}</span>
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class NotFound extends Vue {}
</script>
<style scoped>
.not-found-button {
  border: 0.6000000238418579px solid var(--color-white);
  border-radius: 2px;
  opacity: 1;
  background-color: transparent;
}
.not-found-button > span {
  text-align: center;
  font: normal normal normal 14px/19px Verdana;
  letter-spacing: 0;
  color: var(--color-white);
  opacity: 1;
}
.not-found-button:hover {
  background-color: var(--color-dark-gray);
  border-color: var(--color-dark-gray);
}
.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  margin-top: -45px; /*header*/
  background: var(--color-black) url(/img/ambient_03@2x.png) no-repeat center;
}
.not-found-inner-container {
  margin-right: 0px;
  margin-left: 0px;
}
.not-found-text {
  padding: 100px 100px;
  letter-spacing: 0;
  color: var(--color-white);
  margin: 0px;
}
.not-found-numbers {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: 0;
  margin-right: 0;
  font: normal normal 40 240px/144px ArchiaRegular;
  opacity: 0.45;
}
.not-found-message {
  z-index: 2;
  font: normal normal 40 60px/72px ArchiaThin;
  opacity: 1;
}
@media (max-width: 991px) {
  .not-found-text {
    padding: 75px 75px;
  }
  .not-found-message {
    font: normal normal 20 40px/48px ArchiaThin;
  }
  .not-found-numbers {
    font: normal normal 40 144px/96px ArchiaRegular;
  }
}
@media (max-width: 768px) {
  .not-found-text {
    padding: 50px 50px;
  }
  .not-found-message {
    font: normal normal 16 32px/40px ArchiaThin;
  }
  .not-found-numbers {
    font: normal normal 40 120px/80px ArchiaRegular;
  }
}
</style>
